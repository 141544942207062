/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useContext } from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import { ThemeContext } from "styled-components"

import DagnyFontURL from "../assets/fonts/dagny-regular/dagny-regular.woff"
import DagnyFontURL2 from "../assets/fonts/dagny-regular/dagny-regular.woff2"

import DagnyBoldFontURL from "../assets/fonts/dagny-bold/dagny-bold.woff"
import DagnyBoldFontURL2 from "../assets/fonts/dagny-bold/dagny-bold.woff2"

import DagnyBoldItalicFontURL from "../assets/fonts/dagny-bolditalic/dagny-bolditalic.woff"
import DagnyBoldItalicFontURL2 from "../assets/fonts/dagny-bolditalic/dagny-bolditalic.woff2"

import DagnyItalicFontURL from "../assets/fonts/dagny-italic/dagny-italic.woff"
import DagnyItalicFontURL2 from "../assets/fonts/dagny-italic/dagny-italic.woff2"

import VeneerCleanRoundFontURL from "../assets/fonts/veneer-clean-round/VeneerCleanRound.woff"
import VeneerCleanRoundFontURL2 from "../assets/fonts/veneer-clean-round/VeneerCleanRound.woff2"

import FonoFontURL from "../assets/fonts/fono-regular/396FC6_3_0.woff"
import FonoFontURL2 from "../assets/fonts/fono-regular/396FC6_3_0.woff2"

import FonoBoldFontURL from "../assets/fonts/fono-bold/396FC6_8_0.woff"
import FonoBoldFontURL2 from "../assets/fonts/fono-bold/396FC6_8_0.woff2"

function SEO({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  const themeContext = useContext(ThemeContext)

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `theme-color`,
          content: themeContext.color.primary,
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      <link
        rel="preload"
        as="font"
        href={DagnyFontURL}
        type="font/woff"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        as="font"
        href={DagnyFontURL2}
        type="font/woff2"
        crossOrigin="anonymous"
      />

      <link
        rel="preload"
        as="font"
        href={DagnyBoldFontURL}
        type="font/woff"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        as="font"
        href={DagnyBoldFontURL2}
        type="font/woff2"
        crossOrigin="anonymous"
      />

      <link
        rel="preload"
        as="font"
        href={DagnyBoldItalicFontURL}
        type="font/woff"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        as="font"
        href={DagnyBoldItalicFontURL2}
        type="font/woff2"
        crossOrigin="anonymous"
      />

      <link
        rel="preload"
        as="font"
        href={DagnyItalicFontURL}
        type="font/woff"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        as="font"
        href={DagnyItalicFontURL2}
        type="font/woff2"
        crossOrigin="anonymous"
      />

      <link
        rel="preload"
        as="font"
        href={VeneerCleanRoundFontURL}
        type="font/woff"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        as="font"
        href={VeneerCleanRoundFontURL2}
        type="font/woff2"
        crossOrigin="anonymous"
      />

      <link
        rel="preload"
        as="font"
        href={FonoFontURL}
        type="font/woff"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        as="font"
        href={FonoFontURL2}
        type="font/woff2"
        crossOrigin="anonymous"
      />

      <link
        rel="preload"
        as="font"
        href={FonoBoldFontURL}
        type="font/woff"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        as="font"
        href={FonoBoldFontURL2}
        type="font/woff2"
        crossOrigin="anonymous"
      />
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
